<template>
  <v-list-item two-line>
    <v-list-item-avatar :color="avatarColor">
      <v-img
        v-if="photoUrl"
        :src="photoUrl"
      ></v-img>

      <v-icon v-if="!photoUrl" color="white">
        {{defaultAvatarIcon}}
      </v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{title}}
      </v-list-item-title>

      <v-list-item-subtitle v-if="subtitle">
        {{subtitle}}
      </v-list-item-subtitle>

      <v-divider class="mt-2"></v-divider>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        v-if="route"
        :to="route"
        :target="routeTarget"
        icon
      >
        <v-icon color="primary">fa fa-external-link-alt</v-icon>
      </v-btn>

      <v-btn
        v-if="canRemove"
        icon
      >
        <v-icon
          color="red lighten-2"
          @click="remove"
        >
          fa fa-trash
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    readonly: Boolean,
    disabled: Boolean,
    formDataItem: Object,
    index: Number,
    item: Object,
  },
  methods: {
    remove() {
      this.$apopup.base({
        title: this.$t('delete.confirm'),
        applyCallback: () => this.$emit('remove', this.index),
      })
    },
  },
  computed: {
    avatarColor() {
      if(!this.photoUrl) return 'grey'
      return null
    },
    photoObject() {
      if(typeof this.formDataItem.photo != 'function') return null
      return this.formDataItem.photo(this.item)
    },
    photoUrl() {
      if(!this.photoObject) return null
      return this.$helper.getPhotoUrl(this.photoObject, ['tiny', 'small'])
    },
    defaultAvatarIcon() {
      if(this.formDataItem.defaultAvatarIcon) return this.formDataItem.defaultAvatarIcon
      return 'fa fa-image'
    },
    title() {
      return this.formDataItem.itemTitle(this.item)
    },
    subtitle() {
      if(typeof this.formDataItem.itemSubtitle != 'function') return null
      return this.formDataItem.itemSubtitle(this.item)
    },
    canRemove() {
      if(this.readonly) return false
      if(this.disabled) return false
      return this.formDataItem.removable != false
    },
    route() {
      if(typeof this.formDataItem.itemRoute != 'function') return null
      return this.formDataItem.itemRoute(this.item)
    },
    routeTarget() {
      if(typeof this.formDataItem.itemRouteTarget === 'string') {
        return this.formDataItem.itemRouteTarget
      }
      return '_self'
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
